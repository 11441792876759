import { Outlet } from 'react-router-dom'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Drawer from './Drawer'
import Header from './Header'
import Breadcrumbs from '@/components/@extended/Breadcrumbs'
import menuItems from '@/menu-items'

// ==============================|| MAIN LAYOUT ||============================== //

export default function DashboardLayout() {
    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <Header />
            <Drawer />
            <Box component="main" sx={{ flexGrow: 1, p: { xs: 2, sm: 3, maxWidth: '100vw', overflow: 'hidden' } }}>
                <Toolbar />
                <Breadcrumbs navigation={menuItems} title />
                <Outlet />
            </Box>
        </Box>
    )
}
