import DrawerHeaderStyled from './DrawerHeaderStyled'
import Logo from '@/components/logo'

// ==============================|| DRAWER HEADER ||============================== //

export default function DrawerHeader({ open }: { open: boolean }) {
    return (
        <DrawerHeaderStyled open={open}>
            <Logo sx={{ width: open ? 'auto' : 35, height: 35 }} />
        </DrawerHeaderStyled>
    )
}
