import Loadable from '@/components/Loadable'
import MinimalLayout from '@/layout/MinimalLayout'
import { RouteObject } from 'react-router'

// Lazy-loaded components
const AuthLogin = Loadable(() => import('../pages/authentication/login'))
const AuthRegister = Loadable(() => import('../pages/authentication/register'))

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes: RouteObject = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: 'login',
            element: <AuthLogin />,
        },
        {
            path: 'register',
            element: <AuthRegister />,
        },
    ],
}

export default LoginRoutes
