import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Profile from './Profile'
import Notification from './Notification'
import MobileSection from './MobileSection'
import { MenuItem, Select, Theme } from '@mui/material'
import { useEffect, useMemo } from 'react'
import Avatar from '@/components/@extended/Avatar.tsx'
import { useAppStore } from '@/stores/AppStore'
import { OrganisationHooks, ProjectHooks } from '@/api/backend.query.ts'

export default function HeaderContent() {
    const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const dropDownStyle = {
        border: 0,
        '&:before': {
            borderBottom: 0,
        },
    }

    const {
        activeProject,
        setActiveProject,
        activeOrganisation,
        setActiveOrganisation,
        projects,
        setProjects,
        organisations,
        setOrganisations
    } = useAppStore()


    const {data: projectResponse} = ProjectHooks.getProjects({page: 1, perPage: 10000000})
    useEffect(() => setProjects(projectResponse?.data ?? null), [projectResponse])


    const {data: organisationResponse} = OrganisationHooks.getOrganisations({page: 1, perPage: 10000000})
    useEffect(() => setOrganisations(organisationResponse?.data ?? null), [organisationResponse])

    const filteredProjects = useMemo(() => {
        return projects?.filter((p) => {
            if (!activeOrganisation) return true
            return p.organisation?.id === activeOrganisation?.id
        })
    }, [activeOrganisation, organisations, projects])

    useEffect(() => {
        if (activeOrganisation) setActiveProject(null)
    }, [activeOrganisation])
    //
    // useEffect(() => {
    //     if (!projects) {
    //         // updateProjects()
    //     }
    //
    //     if (!organisations) {
    //         // updateOrganisations()
    //     }
    // }, [])

    return (
        <>
            {!downSM && (
                <Box sx={{ width: '100%', maxWidth: '100%', overflow: 'hidden', display: 'inline-flex' }}>
                    <Select
                        displayEmpty
                        value={organisations ? (activeOrganisation?.id ?? '') : ''}
                        variant={'standard'}
                        inputProps={{ 'aria-label': 'Without label' }}
                        autoWidth={true}
                        sx={dropDownStyle}
                    >
                        {!organisations && <MenuItem value="">Loading...</MenuItem>}
                        {organisations && <MenuItem value="">Select Organisation</MenuItem>}
                        {(organisations ?? []).map((organisation) => (
                            <MenuItem
                                key={organisation.id}
                                value={organisation.id}
                                onClick={() => setActiveOrganisation(organisation)}
                            >
                                <Avatar
                                    size={'xs'}
                                    sx={{
                                        display: 'inline-flex',
                                        mr: 0.5,
                                        fontSize: '0.75rem',
                                        fontWeight: 'bolder',
                                    }}
                                    initialCount={1}
                                >
                                    {organisation.name}
                                </Avatar>
                                {organisation.name}
                            </MenuItem>
                        ))}
                    </Select>

                    <Box sx={{ pr: 2, pl: 2, color: 'grey' }}> / </Box>

                    <Select
                        displayEmpty
                        value={projects ? (activeProject?.id ?? '') : ''}
                        variant={'standard'}
                        inputProps={{ 'aria-label': 'Without label' }}
                        autoWidth={true}
                        sx={dropDownStyle}
                    >
                        {!projects && <MenuItem value="">Loading...</MenuItem>}
                        {projects && <MenuItem value="">Select Project</MenuItem>}
                        {projects &&
                            filteredProjects?.map((project) => (
                                <MenuItem
                                    key={project.id}
                                    value={project.id}
                                    onClick={() => {
                                        setActiveProject(project)
                                    }}
                                >
                                    <Avatar
                                        size={'xs'}
                                        sx={{
                                            display: 'inline-flex',
                                            mr: 0.5,
                                            fontSize: '0.75rem',
                                            fontWeight: 'bolder',
                                        }}
                                        initialCount={1}
                                    >
                                        {project.name}
                                    </Avatar>
                                    {project.name}
                                </MenuItem>
                            ))}
                    </Select>
                </Box>
            )}

            {downSM && (
                <Box sx={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                    <Select
                        displayEmpty
                        value={projects ? (activeProject?.id ?? '') : ''}
                        variant={'standard'}
                        inputProps={{ 'aria-label': 'Without label' }}
                        fullWidth={true}
                        sx={dropDownStyle}
                    >
                        {!projects && !organisations && <MenuItem value="">Loading...</MenuItem>}
                        {projects && organisations && <MenuItem value="">Select Project</MenuItem>}
                        {projects &&
                            organisations &&
                            projects?.map((project) => (
                                <MenuItem
                                    key={project.id}
                                    value={project.id}
                                    onClick={() => {
                                        setActiveProject(project)
                                    }}
                                >
                                    <Avatar
                                        size={'xs'}
                                        sx={{
                                            display: 'inline-flex',
                                            mr: 0.5,
                                            fontSize: '0.75rem',
                                            fontWeight: 'bolder',
                                        }}
                                        initialCount={1}
                                    >
                                        {project.name}
                                    </Avatar>
                                    <strong>{project.name}</strong>&nbsp;/&nbsp;{project.organisation?.name}
                                </MenuItem>
                            ))}
                    </Select>
                </Box>
            )}

            <Notification />
            {!downSM && <Profile />}
            {downSM && <MobileSection />}
        </>
    )
}
