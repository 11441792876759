import { useMemo } from 'react'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import DrawerHeader from './DrawerHeader'
import DrawerContent from './DrawerContent'
import { drawerWidth } from '@/config'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Theme } from '@mui/material/styles'
import { useMenuStore } from '@/stores/MenuStore.tsx'

export default function MainDrawer() {
    const { isDashboardDrawerOpened: drawerOpen, setDrawOpened } = useMenuStore()
    const drawerContent = useMemo(() => <DrawerContent />, [])
    const drawerHeader = useMemo(() => <DrawerHeader open={drawerOpen} />, [drawerOpen])
    const downSM = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const ourWidth = drawerOpen ? drawerWidth : 0
    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1200 }} aria-label="mailbox folders">
            <Drawer
                variant={downSM ? 'temporary' : 'persistent'}
                open={drawerOpen}
                onClose={() => setDrawOpened(false)}
                ModalProps={{ keepMounted: true }}
                transitionDuration={0}
                sx={{
                    width: ourWidth,
                    flexShrink: 0,
                    marginLeft: 0,
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: ourWidth,
                        marginLeft: 0,
                        borderRight: '1px solid',
                        borderRightColor: 'divider',
                        backgroundImage: 'none',
                        boxShadow: 'inherit',
                    },
                }}
            >
                {drawerHeader}
                {drawerContent}
            </Drawer>
        </Box>
    )
}
