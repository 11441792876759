import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import AppBarStyled from './AppBarStyled'
import HeaderContent from './HeaderContent'
import { useMenuStore } from '@/stores/MenuStore'
import MenuFoldOutlined from '@ant-design/icons/MenuFoldOutlined'
import MenuUnfoldOutlined from '@ant-design/icons/MenuUnfoldOutlined'

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

export default function Header() {
    const theme = useTheme()
    const downLG = useMediaQuery(theme.breakpoints.down('sm'))

    const { isDashboardDrawerOpened, toggleDrawer } = useMenuStore()

    const iconBackColor = 'grey.100'
    const iconBackColorOpen = 'grey.200'

    // common header
    const mainHeader = (
        <Toolbar>
            <IconButton
                disableRipple
                aria-label="open drawer"
                onClick={toggleDrawer}
                edge="start"
                color="secondary"
                sx={{
                    color: 'text.primary',
                    bgcolor: isDashboardDrawerOpened ? iconBackColorOpen : iconBackColor,
                    ml: { xs: 0 },
                    mr: 2,
                }}
            >
                {!isDashboardDrawerOpened ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </IconButton>
            <HeaderContent />
        </Toolbar>
    )

    // app-bar params
    const barStyle = {
        borderBottom: `1px solid ${theme.palette.divider}`,
        // boxShadow: theme.customShadows.z1
    }

    return (
        <>
            {!downLG ? (
                <AppBarStyled open={isDashboardDrawerOpened} elevation={0} sx={barStyle} color={'inherit'}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar elevation={0} sx={barStyle} color={'inherit'}>
                    {mainHeader}
                </AppBar>
            )}
        </>
    )
}
