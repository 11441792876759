import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ThemeCustomization from './themes/index'
import ScrollTop from './components/ScrollTop'
import MainRoutes from './routes/MainRoutes'
import LoginRoutes from './routes/LoginRoutes'
import { useLocaleEffect } from './services/LocaleService'
import { LocaleProvider } from './providers/LocaleProvider'
import { RouteObject } from 'react-router'
import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import ErrorService from '@/services/ErrorService.ts'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

export const queryClient = new QueryClient()

const App: React.FC = () => {
    const isLocaleLoaded = useLocaleEffect()

    const routes: RouteObject[] = [MainRoutes, LoginRoutes]
    const router = createBrowserRouter(routes, {
        basename: import.meta.env.VITE_APP_BASE_NAME,
    })

    const { enqueueSnackbar } = useSnackbar()
    useEffect(() => {
        ErrorService.setEnqueueSnackbar(enqueueSnackbar)
    }, [enqueueSnackbar])

    if (!isLocaleLoaded) {
        return <div></div>
    }

    return (
        <LocaleProvider>
            <ThemeCustomization>
                <ScrollTop>
                    <QueryClientProvider client={queryClient}>
                        <RouterProvider router={router} />
                        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
                    </QueryClientProvider>
                </ScrollTop>
            </ThemeCustomization>
        </LocaleProvider>
    )
}

export default App
