import { createTheme } from '@mui/material/styles'

// ==============================|| DEFAULT THEME - PALETTE ||============================== //

export default function Palette(mode: 'light' | 'dark') {
    const colors = {
        red: [
            '#fff1f0',
            '#ffccc7',
            '#ffa39e',
            '#ff7875',
            '#ff4d4f',
            '#f5222d',
            '#cf1322',
            '#a8071a',
            '#820014',
            '#5c0011',
        ],
        volcano: [
            '#fff2e8',
            '#ffd8bf',
            '#ffbb96',
            '#ff9c6e',
            '#ff7a45',
            '#fa541c',
            '#d4380d',
            '#ad2102',
            '#871400',
            '#610b00',
        ],
        orange: [
            '#fff7e6',
            '#ffe7ba',
            '#ffd591',
            '#ffc069',
            '#ffa940',
            '#fa8c16',
            '#d46b08',
            '#ad4e00',
            '#873800',
            '#612500',
        ],
        gold: [
            '#fffbe6',
            '#fff1b8',
            '#ffe58f',
            '#ffd666',
            '#ffc53d',
            '#faad14',
            '#d48806',
            '#ad6800',
            '#874d00',
            '#613400',
        ],
        yellow: [
            '#feffe6',
            '#ffffb8',
            '#fffb8f',
            '#fff566',
            '#ffec3d',
            '#fadb14',
            '#d4b106',
            '#ad8b00',
            '#876800',
            '#614700',
        ],
        lime: [
            '#fcffe6',
            '#f4ffb8',
            '#eaff8f',
            '#d3f261',
            '#bae637',
            '#a0d911',
            '#7cb305',
            '#5b8c00',
            '#3f6600',
            '#254000',
        ],
        green: [
            '#f6ffed',
            '#d9f7be',
            '#b7eb8f',
            '#95de64',
            '#73d13d',
            '#52c41a',
            '#389e0d',
            '#237804',
            '#135200',
            '#092b00',
        ],
        cyan: [
            '#e6fffb',
            '#b5f5ec',
            '#87e8de',
            '#5cdbd3',
            '#36cfc9',
            '#13c2c2',
            '#08979c',
            '#006d75',
            '#00474f',
            '#002329',
        ],
        blue: [
            '#e6f4ff',
            '#bae0ff',
            '#91caff',
            '#69b1ff',
            '#4096ff',
            '#1677ff',
            '#0958d9',
            '#003eb3',
            '#002c8c',
            '#001d66',
        ],
        geekblue: [
            '#f0f5ff',
            '#d6e4ff',
            '#adc6ff',
            '#85a5ff',
            '#597ef7',
            '#2f54eb',
            '#1d39c4',
            '#10239e',
            '#061178',
            '#030852',
        ],
        purple: [
            '#f9f0ff',
            '#efdbff',
            '#d3adf7',
            '#b37feb',
            '#9254de',
            '#722ed1',
            '#531dab',
            '#391085',
            '#22075e',
            '#120338',
        ],
        magenta: [
            '#fff0f6',
            '#ffd6e7',
            '#ffadd2',
            '#ff85c0',
            '#f759ab',
            '#eb2f96',
            '#c41d7f',
            '#9e1068',
            '#780650',
            '#520339',
        ],
        grey: {
            0: '#ffffff',
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#f0f0f0',
            300: '#d9d9d9',
            400: '#bfbfbf',
            500: '#8c8c8c',
            600: '#595959',
            700: '#262626',
            800: '#141414',
            900: '#000000',
            A50: '#fafafb',
            A100: '#fafafa',
            A200: '#bfbfbf',
            A400: '#434343',
            A700: '#1f1f1f',
            A800: '#e6ebf1',
        },
    }

    // const paletteColor = ThemeOption(colors)

    return createTheme({
        palette: {
            mode,
            common: {
                black: '#000',
                white: '#fff',
            },
            ...colors,
            text: {
                primary: colors.grey[700],
                secondary: colors.grey[500],
                disabled: colors.grey[400],
            },
            action: {
                disabled: colors.grey[300],
            },
            divider: colors.grey[200],
            background: {
                paper: colors.grey[0],
                default: colors.grey[200],
            },
        },
    })
}
