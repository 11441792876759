// material-ui
import { styled, useTheme } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'

// project import
import { drawerWidth } from '@/config'

// ==============================|| HEADER - APP BAR STYLED ||============================== //

interface AppBarProps {
    open: boolean
}

const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop: string) => prop !== 'open' })(({
    open,
}: AppBarProps) => {
    const theme = useTheme()
    return {
        zIndex: theme.zIndex.drawer + 1,
        left: 0,
        // transition: theme.transitions.create(['width', 'margin'], {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.leavingScreen,
        // }),
        ...(!open && {
            width: `calc(100%)`,
        }),
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            // transition: theme.transitions.create(['width', 'margin'], {
            //     easing: theme.transitions.easing.sharp,
            //     duration: theme.transitions.duration.enteringScreen,
            // }),
        }),
    }
})

export default AppBarStyled
