import React, { ReactNode, useEffect, useState } from 'react'
import { getLocale } from 'react-i18nify'
import { loadAndSetTranslations, setUserLocale, SupportedLocale } from '../services/LocaleService'
import { LocaleContext, LocaleContextType } from '../contexts/LocaleContext'

interface LocaleProviderProps {
    children: ReactNode
}

export const LocaleProvider: React.FC<LocaleProviderProps> = ({ children }) => {
    const [locale, setLocaleState] = useState<SupportedLocale>(getLocale() as SupportedLocale)

    const updateLocale = async (newLocale: SupportedLocale) => {
        await setUserLocale(newLocale)
        setLocaleState(newLocale)
    }

    useEffect(() => {
        const handleLocaleChange = async () => {
            const currentLocale = getLocale() as SupportedLocale
            await loadAndSetTranslations(currentLocale)
            setLocaleState(currentLocale)
        }

        window.addEventListener('localeChanged', handleLocaleChange)

        return () => {
            window.removeEventListener('localeChanged', handleLocaleChange)
        }
    }, [])

    const contextValue: LocaleContextType = {
        locale,
        setLocale: updateLocale,
    }

    return <LocaleContext.Provider value={contextValue}>{children}</LocaleContext.Provider>
}
